import Vue from 'vue'
import Vuex, {Store} from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  strict: false,
  state: () => ({
    device:"",
  }),
  mutations: {
    setToPc(state) {
      state.device = "pc";
    },
    setToMobile(state) {
      state.device = "mobile";
    },
  },
  actions: {
    setToPc() {
      this.commit("setToPc");
    },
    setToMobile() {
      this.commit("setToMobile");
    },
  },
  modules: {
  }
})
