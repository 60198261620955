








import { Component, Vue } from 'vue-property-decorator';
import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN'
import { Utils } from './utils/utils';

@Component
export default class App extends Vue {
    private zh_CN = zhCN;
    private created() {
        Utils.Init();
        if(document.body.clientWidth <= 960 ) {
            this.$store.dispatch("setToMobile");
        } else {
            this.$store.dispatch("setToPc");
        }
        window.addEventListener("resize",this.onWindowResize.bind(this));
    }

    private onWindowResize() {
        if(document.body.clientWidth <= 960 && this.$store.state.device == "pc") {
            this.$store.dispatch("setToMobile");
            this.$root.$emit("onDeviceChanged","mobile");
        }
        if(document.body.clientWidth > 960 && this.$store.state.device == "mobile") {
            this.$store.dispatch("setToPc");
            this.$root.$emit("onDeviceChanged","pc");
        }
    }
}
